.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* loading dots */

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

/* Material table style */
.MuiTableCell-root {
  padding: 6px !important;
}

.detaillist {
  color: black;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 400px;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.detailphone {
  color: black;
  transition: 0.4s;
  padding-top: 6px;
  padding-bottom: 6px;
  display: grid;
  grid-template-columns: 90px auto 70px;
  grid-column-gap: 0px;
  grid-row-gap: 6px;
  grid-template-areas: "detailheader detailvalues detailbutton";
  border-bottom: solid;
  border-width: thin;
  border-color: rgb(243, 241, 241);
}
.detailphone:hover {
  color: black;
  background-color: rgb(245, 244, 244);
}
.detailvalues {
  justify-self: start;
  grid-area: detailvalues;
}
.detailbutton {
  text-align: center;
  transition-duration: 0.4s;
  width: 60px;
  border-radius: 4px;
  grid-area: detailbutton;
  justify-self: end;
  padding-right: 10px;
}
.detailbutton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(206, 206, 206);
}
.detailheader {
  grid-area: detailheader;
  padding-left: 10px;
}

body {
  background-image: linear-gradient(90deg, rgb(32, 32, 32), rgb(56, 56, 56));
}
